/* eslint-disable array-callback-return */
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { ScrollMessageRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

gsap.registerPlugin(ScrollTrigger);

type TScrollMessage = {
  content: ScrollMessageRecord;
};

const StyledHero = styled.section`
  background-color: var(--grey);
  color: var(--white);

  &.light {
    background-color: var(--beige);
    color: var(--grey);
  }
`;

const StyledSlide = styled.div`
  height: 100vh;
  text-align: center;
`;

const StyledInner = styled.div`
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  .title {
    opacity: 0;
    transform: translateY(60px);

    &.idx1 {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h1 {
    font-size: 120px;
    line-height: 120px;
  }

  p {
    margin: 30px;
    font-size: 28px;
  }

  em {
    color: var(--secondary-color);
    font-style: normal;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    h1 {
      font-size: 72px;
      line-height: 72px;
    }

    p {
      margin: 25px;
      font-size: 20px;
    }
  }
`;

export const ScrollMessage = ({ content }: TScrollMessage) => {
  const revealsRef = useRef([]);
  revealsRef.current = [];

  const { messages, style } = content;

  useEffect(() => {
    const ctx = gsap.context(() => {
      revealsRef?.current.forEach((el, idx) => {
        const tl = gsap.timeline({
          scrollTrigger: {
            id: `section-${idx}`,
            trigger: el,
            end: 'bottom top',
            scrub: true,
            start: 'top top',
          },
        });
        if (idx > 0 && idx < revealsRef?.current.length) {
          tl.to(revealsRef?.current[idx - 1].querySelector('.title'), {
            opacity: 0,
            y: -20,
          }).to(el.querySelector('.title'), {
            opacity: 1,
            y: 0,
          });
        } else {
          tl.to(el.querySelector('.title'), {
            opacity: 1,
            y: 0,
          });
        }
      });
    });

    return () => {
      ctx.revert();
    };
  }, []);

  const addToRefs = (el) => {
    if (el && !revealsRef.current.includes(el)) {
      revealsRef.current.push(el);
    }
  };

  return (
    <StyledHero className={style}>
      {messages.map((message, idx) => {
        return (
          <StyledSlide key={message.id} ref={addToRefs}>
            <StyledInner>
              <Container size="narrow">
                <div className={`title idx${idx + 1}`} dangerouslySetInnerHTML={{ __html: message.message }} />
              </Container>
            </StyledInner>
          </StyledSlide>
        );
      })}
      <StyledSlide ref={addToRefs}>
        <StyledInner />
      </StyledSlide>
    </StyledHero>
  );
};
