import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { ServicesGridRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

type TServicesGrid = {
  content: ServicesGridRecord;
};

const StyledServices = styled.section`
  background-color: var(--beige);
  color: var(--grey);
  text-align: center;
  padding: 100px 0;

  h2 {
    margin-bottom: 100px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 0 0 60px;

    h2 {
      margin-bottom: 50px;
    }
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledCard = styled.div`
  text-align: left;
  background-color: var(--white);
  padding: 60px 40px 40px;

  h4 {
    font-size: 38px;
    line-height: 45px;
    height: 170px;
  }

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0%;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 40px 20px 20px;

    h4 {
      font-size: 20px;
      line-height: 26px;
      height: auto;
    }

    p {
      font-size: 14px;
    }
  }
`;

export const ServicesGrid = ({ content }: TServicesGrid) => {
  const { service, heading } = content;

  return (
    <StyledServices>
      <Container padding="no-mobile">
        <h2>{heading}</h2>
        <StyledGrid>
          {service.map((serv, idx) => {
            return (
              <StyledCard key={serv.id}>
                <h4>{serv.heading}</h4>
                <div className={`title idx${idx + 1}`} dangerouslySetInnerHTML={{ __html: serv.content }} />
              </StyledCard>
            );
          })}
        </StyledGrid>
      </Container>
    </StyledServices>
  );
};
