import Head from 'next/head';
import { useRouter } from 'next/router';
import { useState } from 'react';

import Footer from '@/components/Shared/Footer';
import Header from '@/components/Shared/Header';
import type { FooterRecord } from '@/types/Footer.types';
import type { PageRecord } from '@/types/Pages.types';

import BlockSelector from '../Blocks/BlockSelector';

type PageProps = {
  content: PageRecord;
  footer: FooterRecord;
};

const Page = ({ content, footer }: PageProps) => {
  const { metaData } = content;
  const router = useRouter();
  const [mode, setMode] = useState('light');

  return (
    <>
      <Head>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:title" content={metaData.title} key="title" />
        <meta property="og:description" content={metaData.description} key="description" />
        <meta property="og:image" content={metaData.image.responsiveImage.src} key="image" />
        <meta property="og:url" content={`https://teameleven.co.uk${router.asPath}`} key="url" />
        <meta property="og:type" content="website" key="type" />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.image.responsiveImage.src} />
        <meta name="twitter:site" content="@TeamEleven" />
        <meta name="twitter:card" content={metaData.twitterCard} />
      </Head>
      <Header theme={content.headerTheme} />
      {content.pageContent.map((block) => (
        <BlockSelector key={block.id} block={block} setMode={setMode} />
      ))}
      <Footer footer={footer} />
    </>
  );
};

export default Page;
