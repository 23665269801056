import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { TextContentRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

type TTextContent = {
  content: TextContentRecord;
};

const StyledTextContent = styled.section`
  position: relative;
  z-index: 50;
  margin: 120px 0;
  background-color: var(--white);

  h3 {
    margin-bottom: 30px;
  }

  p {
    margin: 0 0 20px;
  }

  a {
    color: var(--grey);
  }

  table {
    color: #333;
    background: white;
    border: 1px solid grey;
    font-size: 12pt;
    border-collapse: collapse;
  }
  table thead th,
  table tfoot th {
    color: #777;
    background: rgba(0, 0, 0, 0.1);
  }
  table caption {
    padding: 0.5em;
  }
  table th,
  table td {
    padding: 0.5em;
    border: 1px solid lightgrey;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 80px 0;
  }
`;

export const TextContent = ({ content }: TTextContent) => {
  const { content: html } = content;

  return (
    <StyledTextContent>
      <Container size="narrow">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Container>
    </StyledTextContent>
  );
};
