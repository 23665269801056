import { HomepageHero } from '@/components/Blocks//HomepageHero';
import { FeaturedWork } from '@/components/Blocks/FeaturedWork';
import { FullWidthImage } from '@/components/Blocks/FullWidthImage';
import { PeopleGrid } from '@/components/Blocks/PeopleGrid';
import { ScrollMessage } from '@/components/Blocks/ScrollMessage';
import { Video } from '@/components/Blocks/Video';

import { LogoGrid } from './LogoGrid';
import { ServicesGrid } from './ServicesGrid';
import { TextContent } from './TextContent';
import { VideoTestimonial } from './VideoTestimonial';

const BlockSelector = ({ block, setMode }) => {
  const type = block.__typename;

  switch (type) {
    // case 'HomepageHeroRecord':
    //   return <HomepageHero key={block.id} content={block} />;
    case 'VideoRecord':
      return <Video key={block.id} content={block} />;
    case 'VideoTestimonialRecord':
      return <VideoTestimonial key={block.id} content={block} />;
    case 'FeaturedWorkRecord':
      return <FeaturedWork key={block.id} content={block} />;
    case 'ScrollMessageRecord':
      return <ScrollMessage key={block.id} content={block} />;
    case 'PeopleGridRecord':
      return <PeopleGrid key={block.id} content={block} />;
    case 'FullWidthImageRecord':
      return <FullWidthImage key={block.id} content={block} />;
    case 'ServicesGridRecord':
      return <ServicesGrid key={block.id} content={block} />;
    case 'TextContentRecord':
      return <TextContent key={block.id} content={block} />;
    case 'LogoGridRecord':
      return <LogoGrid key={block.id} content={block} />;
    default:
      return <></>;
  }
};

export default BlockSelector;
