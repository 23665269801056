import { Image } from 'react-datocms';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { LogoGridRecord } from '@/types/Pages.types';

import { Container } from '../Shared/Container';

type TLogoGrid = { content: LogoGridRecord };

const StyledLogoGrid = styled.div`
  padding: 120px 0;
  background-color: var(--grey);
  color: var(--white);
  text-align: center;
`;

const StyledGrid = styled.section`
  margin: 80px auto 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  gap: 15px;
  max-width: 1440px;

  video,
  image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 40px 0;
    grid-template-columns: repeat(3, 1fr);
    gap: 3px;
  }
`;

export const LogoGrid = ({ content }: TLogoGrid) => {
  const { heading, logos } = content;

  return (
    <StyledLogoGrid>
      <Container>
        <h2>{heading}</h2>
        <StyledGrid>
          {logos.map((logo) => {
            return (
              <div key={logo.id}>
                <Image data={logo.responsiveImage} />
              </div>
            );
          })}
        </StyledGrid>
      </Container>
    </StyledLogoGrid>
  );
};
