import { useState } from 'react';
import { Image } from 'react-datocms';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { PeopleGridRecord, PeopleRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

type TPeopleGrid = {
  content: PeopleGridRecord;
};

const StyledPeopleGrid = styled.section`
  position: relative;
  z-index: 50;
  margin: 120px 0;
  background-color: var(--white);

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    margin: 80px 0;
  }
`;

const StyledHeading = styled.p`
  margin-bottom: 40px;
  font-size: 50px;
  line-height: 61px;
  font-weight: 600;
  font-family: 'Helvetica-Neue';

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    font-size: 28px;
    line-height: 30px;
  }
`;

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 60px 60px;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    grid-template-columns: 1fr 1fr;
    gap: 40px 24px;
  }
`;

const StyledImage = styled.div`
  display: block;
  margin-bottom: 20px;
`;

const StyledCard = styled.div`
  cursor: pointer;

  p.name {
    font-size: 22px;
  }

  p.subheading {
    font-size: 15px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    p.subheading {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }
`;

const StyledModal = styled.div`
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 100vh;
  background-color: var(--white);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: transform 0.4s ease-in;
  transform: translateY(100%);
  z-index: 10000;

  &.open {
    transform: translateY(0%);
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 40px 0 24px;
    height: 100vh;
  }
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    flex-direction: column;
  }
`;

const StyledModalImage = styled.div`
  width: 50%;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const StyledModalText = styled.div`
  width: 50%;
  color: var(--grey);
  padding-left: 80px;

  h4 {
    font-weight: 400;
    font-size: 28px;
  }

  p.subheading {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    overflow: scroll;
    flex-grow: 1;
    width: 100%;
    padding-left: 0;
    height: 50%;

    p.subheading {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
`;

const StyledCloseBtn = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 100;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: var(--grey);
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    right: 12px;
    top: 12px;
    width: 28px;
    height: 28px;
    z-index: 1010;

    &:before,
    &:after {
      left: 15px;
      height: 22px;
    }
  }
`;

export const PeopleGrid = ({ content }: TPeopleGrid) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<PeopleRecord | undefined>();

  const { people } = content;

  const peopleClickHandler = (data: PeopleRecord): void => {
    if (modalOpen) {
      setModalData(data);
    } else {
      setModalData(data);
      setModalOpen(true);
    }
  };

  const closeBtnHandler = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <StyledPeopleGrid>
        <Container>
          <StyledHeading>People</StyledHeading>
          <StyledGrid>
            {people.map((profile) => {
              const { id, name, subheading, portraitImage } = profile;
              return (
                <StyledCard key={id} onClick={() => peopleClickHandler(profile)}>
                  <StyledImage>
                    <Image data={portraitImage.responsiveImage} />
                  </StyledImage>
                  <p className="name">{name}</p>
                  <p className="subheading">{subheading}</p>
                </StyledCard>
              );
            })}
          </StyledGrid>
        </Container>
      </StyledPeopleGrid>
      <StyledModal className={`${modalOpen ? 'open' : ''}`}>
        <StyledCloseBtn
          onClick={() => {
            closeBtnHandler();
          }}
        />
        {modalData && (
          <Container>
            <StyledRow>
              <StyledModalImage>
                <Image data={modalData.squareImage.responsiveImage} />
              </StyledModalImage>
              <StyledModalText>
                <h4>{modalData.name}</h4>
                <p className="subheading">{modalData.subheading}</p>
                <div dangerouslySetInnerHTML={{ __html: modalData.bio }} />
              </StyledModalText>
            </StyledRow>
          </Container>
        )}
      </StyledModal>
    </>
  );
};
