import { Image } from 'react-datocms';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { FullWidthImageRecord } from '@/types/Pages.types';

type TFullWidthImage = {
  content: FullWidthImageRecord;
};

const StyledImage = styled.div`
  position: relative;

  .mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    width: 100%;

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }
  }
`;

export const FullWidthImage = ({ content }: TFullWidthImage) => {
  const { image, mobileImage } = content;
  return (
    <StyledImage>
      <Image className="desktop" data={image.responsiveImage} />
      <Image className="mobile" data={mobileImage.responsiveImage} layout="responsive" />
    </StyledImage>
  );
};
