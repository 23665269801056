/* eslint-disable jsx-a11y/media-has-caption */
import { useCallback, useRef, useState } from 'react';
import { Image } from 'react-datocms';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { VideoRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

type TVideo = {
  content: VideoRecord;
};

const StyledVideoWrap = styled.div`
  position: relative;
  z-index: 10;
  background-color: var(--beige);
  padding: 60px 0;

  video {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const StyledVideo = styled.div`
  display: block;

  .video-overlay {
    opacity: 1;
    visibility: visible;

    &.active {
      opacity: 0;
      visibility: hidden;
      transition: opacity 500ms ease, visibility 500ms ease;
    }
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
  }
`;

const PlayButton = styled.div`
  background: var(--white);
  border-radius: 100px;
  height: 80px;
  position: absolute;
  width: 80px;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all 0.3s ease-in;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 30px;
    border-color: transparent transparent transparent var(--grey);
    position: absolute;
    top: 0;
    left: 0;
    right: -8px;
    bottom: 0;
    margin: auto;
  }

  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    height: 60px;
    width: 60px;

    &:before {
      border-width: 12px 0 12px 15px;
      right: -2px;
    }
  }
`;

export const VideoTestimonial = ({ content }: TVideo) => {
  const { videoFile, desktopPoster } = content;
  const desktopVideoRef = useRef(null);
  const [videoPlaying, setVideoPlaying] = useState(false);

  const handleVideoEnd = useCallback(() => {
    setVideoPlaying(false);
  }, [setVideoPlaying]);

  const playVideo = useCallback(() => {
    setVideoPlaying(true);
    desktopVideoRef.current?.play();
  }, [setVideoPlaying]);

  return (
    <StyledVideoWrap>
      <Container padding="none">
        <StyledVideo>
          <video
            ref={desktopVideoRef}
            id="loop"
            preload="auto"
            playsInline
            width="1280"
            height="720"
            controls
            onEnded={handleVideoEnd}
          >
            <source src={videoFile} />
          </video>
          <div className={`video-overlay ${desktopVideoRef && videoPlaying ? 'active' : ''}`}>
            <Image data={desktopPoster.responsiveImage} layout="fill" objectFit="cover" className="videoThumbnail" />
            <PlayButton onClick={playVideo} />
          </div>
        </StyledVideo>
      </Container>
    </StyledVideoWrap>
  );
};
