import Link from 'next/link';
import { Image } from 'react-datocms';
import styled from 'styled-components';

import { BREAKPOINTS } from '@/theme';
import type { FeaturedWorkRecord } from '@/types/Pages.types';

import { Container } from '../Shared';

type TFeaturedWork = {
  content: FeaturedWorkRecord;
};

const StyledWrap = styled.div`
  margin: 120px 0;
  position: relative;
  z-index: 50;

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    h4 {
      margin-left: 24px;
      margin-right: 24px;
    }

    margin: 40px 0;
  }
`;

const StyledCard = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 60px;
  }

  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
      max-width: 100%;
      img {
        width: 100%;
      }
    }
  }
`;

const StyledHeading = styled.p`
  margin-bottom: 40px;
  font-size: 50px;
  line-height: 61px;
  font-weight: 600;
  font-family: 'Helvetica-Neue';

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    display: none;
    font-size: 18px;
    line-height: 30px;
  }
`;

const StyledText = styled.div`
  position: absolute;
  z-index: 20;
  bottom: 0;
  left: 0;
  color: var(--white);
  padding: 0 60px 50px 60px;

  p.client {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
  }

  p.title {
    margin: 0;
    letter-spacing: -1px;
    max-width: 505px;
    font-weight: 600;
    font-family: 'Helvetica-Neue';
    font-size: 50px;
    line-height: 61px;
  }

  @media screen and (max-width: ${BREAKPOINTS.mobile}) {
    padding: 0 30px 50px 30px;

    p.title {
      font-size: 35px;
      line-height: 40px;
    }
  }
`;

export const FeaturedWork = ({ content }: TFeaturedWork) => {
  const { heading, caseStudies } = content;

  return (
    <StyledWrap id="work">
      <Container padding="no-mobile">
        <StyledHeading>{heading}</StyledHeading>
        {caseStudies.map((caseStudy) => {
          return (
            <StyledCard key={caseStudy.id}>
              <Link href={`/case-study/${caseStudy.slug}`} passHref scroll>
                <a>
                  <StyledText>
                    <p className="client">{caseStudy.client}</p>
                    <p className="title">{caseStudy.headline}</p>
                  </StyledText>
                  <Image className="desktop" data={caseStudy.featuredImage.responsiveImage} layout="responsive" />
                  <Image className="mobile" data={caseStudy.mobile.responsiveImage} layout="responsive" />
                </a>
              </Link>
            </StyledCard>
          );
        })}
      </Container>
    </StyledWrap>
  );
};
